<template>
  <div>
    <el-steps :style="computedStyle"
              finish-status="success"
              :space="stagesSettings.space"
              :direction="stagesSettings.directionVertical?'vertical':'horizontal'"
              :simple="stagesSettings.isSimple"
              align-center>
      <el-step @click.native="onClick(item)" v-for="(item, index) in stages" :key="index"
               :title="item.title"
               :style="item.color"
               :status="item.status"
               :description="item.description"
               :icon="item.icon">
      </el-step>
    </el-steps>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'a-stages',
  inject: {
    addMainTab: {
      default: () => {
      }
    },
    openRegistryCard: {
      default: () => {}
    },
    getRegistryRecordId: {
      default: () => {
      }
    },
    getRegistryId: {
      default: () => {
      }
    },
    forceUpdateSettingsPanel: {
      default: () => () => {
      }
    }
  },
  provide () {
    return {
      getRegistryId: function () {
        return null
      },
      addMainTab: function () {
        return null
      }
    }
  },
  mixins: [mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    CSS: {
      type: String,
      description: 'CSS стили'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    stagesSettings: {
      type: Object,
      default () {
        return {
          directionVertical: false,
          statuses: [{ id: 0, name: 'wait', color: null, icon: null },
            { id: 1, name: 'process', color: null, icon: null },
            { id: 2, name: 'finish', color: null, icon: null },
            { id: 3, name: 'error', color: null, icon: null },
            { id: 4, name: 'success', color: null, icon: null }],
          type: '',
          isSimple: false,
          registryId: null,
          extendObject: null,
          title: '',
          description: '',
          card: {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          }
        }
      },
      editor: 'Stages'
    }
  },
  data () {
    return {
      stages: []
    }
  },
  mounted: async function () {
    await this.loadData()
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    'stagesSettings.statuses' () {
      this.loadData()
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      return css
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.stages
      )

      return builder.buildAsQueryParams()
      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = `=`
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         filters.push(`${item.alias}${type}${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${eq},${item.attribute}`)
      //     } else if (item.type === 'current_user' && item.alias) {
      //       filters.push(`${item.alias}${type}${this.$store.getters['Authorization/userId']}`)
      //     }
      //   })
      // }
      // return filters
    }
  },
  methods: {
    async getRequest (id) {
      let response = await this.$http.get(`${this.$config.api}/datawarehouseservice/query/${id}?${this.dataFilters.join('&')}`)
      return response.data
    },
    async getApproval (id) {
      let response = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.getRegistryId()}/records/${this.getRegistryRecordId()}/approvals/${this.stagesSettings.approvalId}`)
      const approvals = []
      response.data.forEach((item) => {
        let approval = {}
        approval = {
          title: item.name,
          status: item.status === true ? 'success' : 'wait',
          description: item.approval_date
        }
        approvals.push(approval)
      })
      return approvals
    },
    setStyleStage (stages) {
      stages.map((stage) => {
        const status = stage.status ? stage.status : 'wait'
        const setting = this.stagesSettings.statuses.find((s) => s.name === status)
        stage.color = setting && setting.color ? setting.color : null
        stage.icon = setting && setting.icon ? setting.icon : null
        return stage
      })
      return stages
    },
    getIcon (status) {
      const setting = this.stagesSettings.statuses.find((s) => s.name === status)
      return setting && setting.icon ? setting.icon : null
    },
    setStagesByParams (data) {
      let stages = []
      data.forEach((data) => {
        let stage = data
        stage.title = data[this.stagesSettings.title]
        stage.description = data[this.stagesSettings.description]
        stage.status = data.status
        stages.push(stage)
      })
      return stages
    },
    async loadData () {
      var stages = []
      if (!this.getModel().id) {
        this.stagesSettings.statuses.forEach((status) => {
          let stage = {
            title: status.title,
            description: 'Описание',
            status: status.title,
            icon: status.icon,
            color: status.color
          }
          stages.push(stage)
        })
      } else {
        const type = this.stagesSettings.type

        if (type === 'request') {
          const requestId = this.stagesSettings.requestsId
          stages = await this.getRequest(requestId)
          stages = this.setStagesByParams(stages)
        } else if (type === 'approval') {
          const approvalId = this.stagesSettings.approvalId
          stages = await this.getApproval(approvalId)
        } else if (type === 'extend_object') {

        } else if (type === 'registry') {

        }
        stages = this.setStyleStage(stages)
      }
      this.stages = stages
    },
    async onClick (stage) {
      if (this.stagesSettings.type !== 'request') {
        return false
      }
      const registryIdField = this.stagesSettings.card.queryRegistry
      const fieldIdField = this.stagesSettings.card.queryRecord
      const cardIdField = this.stagesSettings.card.queryCard
      if (!registryIdField || !fieldIdField || !cardIdField) {
        console.warn('wrong parameters', this.stagesSettings.card)
        return false
      }
      const registryId = stage[registryIdField]
      const recordId = stage[fieldIdField]
      const cardId = stage[cardIdField]
      if (!registryId || !recordId || !cardId) {
        console.warn('wrong parameters', stage)
        return false
      }
      const readOnly = this.stagesSettings.card.type === 'read'
      let initialData = {}
      let defaults = this.getDefaultsForCard()

      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      if (this.stagesSettings.card.isWindow) {
        this.openRegistryCardInWindow(cardId, false, recordId, registryId, initialData)
      } else {
        let me = this
        this.openRegistryCard({
          registryId: registryId,
          cardId: cardId,
          cardName: '',
          recordId: recordId,
          initialData: initialData,
          registry: {
            readonly: readOnly,
            addRecord: () => {},
            updateRecord: (recordId) => {
              if (me.stagesSettings.card.fieldId) {
                const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === me.stagesSettings.card.fieldId)
                if (selectField) {
                  if (typeof selectField[0].reloadById === 'function') {
                    selectField[0].reloadById(recordId)
                  } else if (typeof selectField[0].loadData === 'function') {
                    selectField[0].loadData()
                  }
                }
              }
            }
          }
        })
      }
    },
    openRegistryCardInWindow (cardId, readOnly, recordId, registryId, initialData) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (this.stagesSettings.card.windowWidth) {
        customClass += `dashboard_window_width_${this.stagesSettings.card.windowWidth}`
      }
      let me = this
      this.$msgbox({
        title: this.stagesSettings.card.windowTitle,
        customClass: customClass,
        message: h('registry-card', { props: {
          cardId: cardId,
          registryId: registryId,
          readonly: readOnly,
          recordId: recordId,
          initialData: initialData
        },
        on: {
          cancelChanges () {
            me.$msgbox.close()
          },
          recordAdded: (recordId) => {
            me.$set(me.getModel(), me.stagesSettings.card.fieldId, recordId)
          },
          recordEdited: (recordId) => {
            if (me.stagesSettings.card.fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === me.stagesSettings.card.fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          }
        },
        key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.stagesSettings.card.defaults) {
        this.stagesSettings.card.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    }
  }
}
</script>
